function isWebGLAvailable() {
    try {
        var canvas = document.createElement( 'canvas' );
        return !! ( window.WebGLRenderingContext && ( canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) ) || window.WebGL2RenderingContext && canvas.getContext( 'webgl2' ) );

    } catch ( error ) {
        console.warn(error)
        return false;
    }
}

export {isWebGLAvailable};