import * as THREE from 'three';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

import * as dat from 'lil-gui';

const debug = window.location.href.split('#')[1] == "debug";         // Add #debug to the url to enable debug gui

const debugGui = new dat.GUI({title: "Debug"});

if (!debug) {
    debugGui.hide();
}

export class Canvas {
    constructor(element, debug = false){
        this.scene = new THREE.Scene();
        this.clock = new THREE.Clock();
        this.sizes = {
            width: window.innerWidth,
            height: window.innerHeight,
            fov: 70,
            aspect: window.innerWidth / window.innerHeight,
            near: 0.1,
            far: 10
        };
        this.camera = this._addCamera(this.sizes);
        this.renderer = this._addRenderer(element, this.sizes);
        this.controls = this._addControls(this.camera, element);
        this.lights = this._addLights();
        this.mouse = new THREE.Vector2();
        this.raycaster = new THREE.Raycaster();
        this.loadingManager = new THREE.LoadingManager();
        this.models = null;
        this.gui = this._addGui(debug);
    }

    _addGui(debug){
        const debugGui = new dat.GUI({title: "Debug"});
        if (!debug) {
            debugGui.hide();
        }else {
            const controlsFolder = debugGui.addFolder('Controls');
            controlsFolder.add(this.controls, 'zoomSpeed', 0, 5);
            controlsFolder.add(this.controls, 'rotateSpeed', 0, 5);
            controlsFolder.add(this.controls, 'dampingFactor', 0, 1);
            controlsFolder.add(this.controls, 'minDistance', 0, 20);
            controlsFolder.add(this.controls, 'maxDistance', 0, 20);
            controlsFolder.close()

            const rendererFolder = debugGui.addFolder('Renderer');
            rendererFolder.add(this.renderer.shadowMap, 'enabled');
            rendererFolder.add(this.renderer, 'toneMappingExposure');
            rendererFolder.add(this.renderer, 'physicallyCorrectLights');
            rendererFolder.close();

            const dirLightFolder = debugGui.addFolder('Directional Light');
            dirLightFolder.add(this.lights.dirLight.position, 'x');
            dirLightFolder.add(this.lights.dirLight.position, 'y');
            dirLightFolder.add(this.lights.dirLight.position, 'z');
            dirLightFolder.add(this.lights.dirLight, 'castShadow');
            dirLightFolder.add(this.lights.dirLight.shadow.mapSize, 'width');
            dirLightFolder.add(this.lights.dirLight.shadow.mapSize, 'height');
            dirLightFolder.add(this.lights.dirLight.shadow.camera, 'near');
            dirLightFolder.add(this.lights.dirLight.shadow.camera, 'far');
            dirLightFolder.close();
        }
        return debugGui
    }

    _addCamera(sizes){
        const camera = new THREE.PerspectiveCamera( sizes.fov, sizes.aspect, sizes.near, sizes.far);
        camera.position.set( 0, 0, 5);
        this.scene.add(camera);

        return camera;
    }

    _addRenderer(element, sizes){
        const renderer = new THREE.WebGLRenderer( { antialias: true, canvas: element } );
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        renderer.physicallyCorrectLights = true;
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.outputColorSpace = THREE.SRGBColorSpace;
        renderer.toneMappingExposure = 5;
        return renderer;
    }

    _addLights(){
        const lights = {};
        const dirLight = new THREE.DirectionalLight();
        const dirHelper = new THREE.DirectionalLightHelper( dirLight, 1, "#000000" );

        dirLight.position.set( 5, -10, 10 );
        dirLight.castShadow = true;
        dirLight.shadow.mapSize.width = 512;
        dirLight.shadow.mapSize.height = 512;
        dirLight.shadow.camera.near = 0.5;
        dirLight.shadow.camera.far = 500;

        const shadowCam = dirLight.shadow.camera;
        shadowCam.left = shadowCam.bottom = -1;
        shadowCam.right = shadowCam.top = 1;

        const ambLight = new THREE.AmbientLight();

        this.scene.add(dirLight);
        // this.scene.add(dirHelper);
        this.scene.add(ambLight);
        lights.dirLight = dirLight;
        lights.ambLight = ambLight;

        return lights;
    }

    _updateLights(modelObject){
        this.lights.dirLight.color.setStyle(modelObject.backgroundColor);
        this.lights.ambLight.color.setStyle(modelObject.backgroundColor);
        this.lights.dirLight.intensity = modelObject.dirLightLevel;
        this.lights.ambLight.intensity = modelObject.ambLightLevel;

        const lightsFolder = this.gui.addFolder('Lights');
        lightsFolder.add(this.lights.dirLight, 'intensity', -10, 10);
        lightsFolder.addColor(this.lights.dirLight, 'color');
        lightsFolder.add(this.lights.ambLight, 'intensity', -10, 10);
        lightsFolder.addColor(this.lights.ambLight, 'color');
        lightsFolder.close();
    }

    _addControls(camera, element){
        const controls = new OrbitControls( camera, element );
        controls.enablePan = false;
        controls.enableZoom = true;
        controls.zoomSpeed = .5;
        controls.maxDistance = 6;
        controls.minDistance = 2.5;
        controls.rotateSpeed = 1;
        controls.enableDamping = true;
        controls.dampingFactor = 0.1;

        return controls;
    }

    keyPressed(key){
        let zoom;
        switch(key) {
            case 'ArrowUp':
                zoom = this.camera.zoom + 0.1;
                if (zoom > 1.5) zoom = 1.5;
                this.camera.zoom = zoom;
                this.camera.updateProjectionMatrix();
                break;
            case 'ArrowDown':
                zoom = this.camera.zoom - 0.1;
                if (zoom < .5) zoom = .5
                this.camera.zoom = zoom;
                this.camera.updateProjectionMatrix();
                break;
            case 'ArrowLeft':
                this.models.rotateY(-0.1);
                break;
            case 'ArrowRight':
                this.models.rotateY(0.1);
                break;
        }
    }

    addModel(modelObject, callback, timeout = 5000){
        let startLoad;
        let endLoad;
        this.loadingManager.onStart = function ( url, itemsLoaded, itemsTotal ) {
            startLoad = Date.now();
        };

        this.loadingManager.onLoad = function () {
            endLoad = Date.now();
            timeout = timeout - (endLoad - startLoad);

            setTimeout(function(){
                callback('loading-id');
            }, timeout);
        };

        this.loadingManager.onError = function ( url ) {
            console.log( 'There was an error loading ' + url );
        };

        new GLTFLoader(this.loadingManager).load(
            modelObject.path,
            gltf => {
                const model = gltf.scene
                model.castShadow = true;
                model.receiveShadow = true;
                model.position.set(0, -2, 0);
                this.models = model;
                this.scene.add(model);

                this._updateLights(modelObject);
                this.renderer.setClearColor(modelObject.backgroundColor);
            }
        );
    }

    onWindowResize(){
        const width = window.innerWidth;
        const height = window.innerHeight;
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize( width, height );
        this.renderer.setPixelRatio( window.devicePixelRatio );
    }

    hoverObject(event) {
        this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
      
        this.raycaster.setFromCamera( this.mouse, this.camera );
        const intersects = this.raycaster.intersectObjects( this.models.children , true);
        if(intersects.length > 0) {
            return {status: true, name:intersects[0].object.name};
        } else {
            return {status: false, name: null};
        }
    }

    clickObject(event) {
        this.mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        this.mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;
    
        this.raycaster.setFromCamera( this.mouse, this.camera );
        const intersects = this.raycaster.intersectObjects( this.models.children , true);
    
        if(intersects.length > 0) {
            return {status: true, name:intersects[0].object.name};
        } else {
            return {status: false, name: null};
        }
    }
    
    render() {
        this.renderer.render(this.scene, this.camera);
    }

    animate() {
        const elapsedTime = this.clock.getElapsedTime();
        this.controls.update();
        this.render();
        window.requestAnimationFrame(this.animate.bind(this));
    }
}
