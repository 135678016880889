import { Canvas } from './threeJs/three.js';
import { isWebGLAvailable } from './threeJs/webGL.js';

const TRACKINGURL = "https://script.google.com/macros/s/AKfycbxY7SSK57_j7GjLHrH3ropuIG1j_vuESGy8Z9FXVTpR65PsD4vRtDPYdtMwY81hBeZz/exec";

const ALLOW_DEBUG = true;
const LOADING_TIME = 2000;
const ANIMATION_TIME = 1000;
let CANVAS;

/* Model Parameters */
let MODELS = {};
MODELS.BOUQUET_COLOR = {
    path: '/models/pario_bouquet.glb',
    alt: '/img/alternative.png',
    altText: 'bouquet',
    backgroundColor: "#FFD1D0",
    shadowColor: "#455A64",
    shadowOpacity: 0.25,

    dirLightColor: "#FFD1D0", 
    dirLightLevel: 3.2,
    ambLightColor: "#FFD1D0",
    ambLightLevel: 2.0,
};

MODELS.BOUQUET = {
    path: '/models/pario_bw.glb',
    alt: '/img/alternative.png',
    altText: 'bouquet',
    backgroundColor: "#FFD1D0",
    modelColor: "#ffffff",
    shadowColor: "#455A64",
    shadowOpacity: 0,
 
    dirLightColor: "#ffffff", 
    dirLightLevel: 18.3,
    ambLightColor: "#FFAFAD",
    ambLightLevel: 1.2,
};

init(MODELS.BOUQUET_COLOR);

function init(model){
    document.body.style.backgroundColor = model.backgroundColor;
    window.dataLayer = window.dataLayer || [];

    if ( isWebGLAvailable() ){
        console.log("WebGL available" )
        // load three.js
        let debug = false;
        if(ALLOW_DEBUG){
            debug = window.location.href.split('#')[1] == "debug";
        }
    
        trackEvent("loadSite", "webGL");
        
        CANVAS = new Canvas(document.querySelector('#canvas-id'), debug);
        CANVAS.addModel(model, hideLoading, LOADING_TIME);
        CANVAS.animate();
        window.addEventListener( 'resize', function() {
            CANVAS.onWindowResize();
        }, false );

        setTimeout(function(){
            enableCanvasEvents();
        }, LOADING_TIME);

        document.getElementById('info-alternative-id').addEventListener('click', function altElement() {
            toggleInfo(false, false);
            loadAlternative(model);
            this.removeEventListener('click', altElement);
        })

    }else{
        console.log("No WebGL");
        loadAlternative(model);
        hideLoading('loading-id', LOADING_TIME);
    }

    enableFrameEvents();

    window.addEventListener('resize', resizeWindow);
}


function hideLoading(id, timeout=1000) {
    const loading = document.getElementById(id);
    loading.classList.add('fade-out');
    document.getElementById('navHints-container-id').classList.add('fade-out-delay');
    setTimeout( () => {
        loading.style.display = "none";
    }, timeout);
}

function resizeWindow(){
    if(document.getElementById('info-container-id').classList.contains('info-container-show')){
        toggleInfo(true);
    }
    if(document.getElementById('topic-container-id').classList.contains('topic-container-show')){
        openTopic(false);
    }
}

function enableFrameEvents(){
    document.getElementById('frame-button-id').addEventListener('click', clickInfo);
    document.getElementById('frame-button-id').addEventListener('keypress', clickInfo);
    document.getElementById('frame-overlay-id').addEventListener('click', clickOverlay);
}

function disableFrameEvents(){
    document.getElementById('frame-button-id').removeEventListener('click', clickInfo);
    document.getElementById('frame-button-id').removeEventListener('keypress', clickInfo);
    document.getElementById('frame-overlay-id').removeEventListener('click', clickOverlay);
}

function enableCanvasEvents(){
    if(document.getElementById('canvas-id')) {
        document.getElementById('canvas-id')?.classList.remove('canvas-disabled');
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('pointerdown', onPointerDown);
        window.addEventListener('pointerup', onPointerUp);
        document.body.addEventListener('keydown', keyPress);
    }
}

function disableCanvasEvents(){
    document.getElementById('canvas-id')?.classList.add('canvas-disabled');
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('pointerdown', onPointerDown);
    window.removeEventListener('pointerup', onPointerUp);
}

function keyPress(event){
    if(event.keyCode === 9 ){
        window.removeEventListener('mousemove', onMouseMove); 
        document.querySelector("#object-id").innerText = "";
        if(event.srcElement.id == "hidden-tab-cotton"){
            window.addEventListener('mousemove', onMouseMove); 
        }
    }
    if(event.keyCode === 13 && event.srcElement.classList.contains('hidden-tab')) {
        if(document.getElementById('topic-container-id').classList.contains('topic-container-show')){
            openTopic(false);
            return;
        }
        if(event.srcElement.id == "hidden-tab-sugar"){
            trackEvent("topic", "sugar");
            changeTopic("template-sugar");
        }else if(event.srcElement.id == "hidden-tab-bh"){
            trackEvent("topic", "blackhaw");
            changeTopic("template-bh");
        }else if(event.srcElement.id == "hidden-tab-cotton"){
            trackEvent("topic", "cotton");
            changeTopic("template-cotton");
        }
        toggleInfo(false);
        openTopic(true);
        
        return;
    }
    CANVAS.keyPressed(event.key);
}

function clickOverlay(){
    toggleInfo(false);
    openTopic(false);
}

function clickInfo(){
    toggleInfo(true);
    openTopic(false);
}

function openOverlay(show = true){
    const overlay = document.getElementById('frame-overlay-id');
    const info = document.getElementById('info-container-id');
    const topic = document.getElementById('topic-container-id');

    if(show){
        overlay.classList.add('frame-overlay-show');
    }else{
        if(info.classList.contains('info-container-show')) return;
        if(topic.classList.contains('topic-container-show')) return;
        overlay.classList.remove('frame-overlay-show');
    }
}


function toggleInfo(toggle = true){
    const container = document.getElementById('info-container-id');
    const content = document.getElementById('info-content-id');
    
    disableFrameEvents();
    disableCanvasEvents();

    if(toggle && !container.classList.contains('info-container-show')){
        container.classList.add('info-container-show');
        content.style.display = "block";
        openOverlay(true);
        trackEvent("info", "show");

    }else if(toggle && container.classList.contains('info-container-show')){
        container.classList.remove('info-container-show');
        setTimeout(function(){
            if(!container.classList.contains('info-container-show')){
                content.style.display = "none";
                openOverlay(false);
                enableCanvasEvents();
            }
        }, ANIMATION_TIME)
    }else{
        container.classList.remove('info-container-show');
        setTimeout(function(){
            if(!container.classList.contains('info-container-show')){
                content.style.display = "none";
                openOverlay(false);
                enableCanvasEvents();
            }
        }, ANIMATION_TIME);
    }

    setTimeout(function(){
        enableFrameEvents();
    }, ANIMATION_TIME);
}

function openTopic(open){
    const container = document.getElementById('topic-container-id');
    const content = document.getElementById('topic-content-id');
    const element = document.querySelector("#object-id");
    disableCanvasEvents();
    disableFrameEvents();

    if(open && !container.classList.contains('topic-container-show')){
        container.classList.add('topic-container-show');
        content.style.display = "block";
        element.innerText = "";
        openOverlay(true);

    }else if(!open && container.classList.contains('topic-container-show')){
        container.classList.remove('topic-container-show');
        setTimeout(function(){
            if(!container.classList.contains('topic-container-show')){
                content.style.display = "none";
                openOverlay(false);
                enableCanvasEvents();
            }
        }, ANIMATION_TIME);
    }
    
    setTimeout(function(){
        enableFrameEvents();
    }, ANIMATION_TIME);
}

function changeTopic(id){
    const topic = document.getElementById(id);
    const topicContainer = document.getElementById('topic-container-id');
    topicContainer.innerHTML = topic.innerHTML;
}

function onMouseMove(event){
    const response = CANVAS.hoverObject(event);
    const element = document.querySelector("#object-id");
    const canvasElement = document.querySelector("#canvas-id");
    
    if(document.getElementById('topic-container-id').classList.contains('topic-container-show')) return;
    if(document.getElementById('info-container-id').classList.contains('info-container-show') ) {
        element.innerText = "";
        return;
    }
    if(response.status){
        canvasElement.classList.add('canvas-pointer');
        if(response.name.toLowerCase().includes("cotton")){
            element.innerText = "cotton";
        }else if(response.name.toLowerCase().includes("berry")){
            element.innerText  = "blackhaw";
        }else if(response.name.toLowerCase().includes("sugar")){
            element.innerText  = "sugar cane";
        }
    }else{
        element.innerText = "";
        canvasElement.classList.remove('canvas-pointer');
    }
}

function onPointerDown(event){
    const canvasElement = document.querySelector("#canvas-id");
    const container = document.getElementById('topic-container-id');
    const response = CANVAS.clickObject(event);

    if(event.srcElement.href != null) return;

    if(response.status && !container.classList.contains('topic-container-show')){
        disableCanvasEvents();
        if(response.name.toLowerCase().includes("cotton")){
            trackEvent("topic", "cotton");
            changeTopic("template-cotton");
        }else if(response.name.toLowerCase().includes("berry")){
            trackEvent("topic", "blackhaw");
            changeTopic("template-bh");
        }else if(response.name.toLowerCase().includes("sugar")){
            trackEvent("topic", "sugar");
            changeTopic("template-sugar");
        }
        toggleInfo(false);
        openTopic(true);
        
    }else{
        canvasElement.classList.add('canvas-grab');
    }
}

function onPointerUp(){
    const canvasElement = document.querySelector("#canvas-id");
    canvasElement.classList.remove('canvas-grab');
}

function loadAlternative(model) {
    trackEvent("loadSite", "alt");
    if(document.getElementById('canvas-id')){
        document.getElementById('canvas-id').remove();
    }
    const alt = document.querySelector("#alternative-id");
    const altImage = document.querySelector("#alt_img");
    altImage.src = model.alt;
    altImage.alt = model.altText;
    alt.classList.remove("alternative-hide");

    if ( isWebGLAvailable() ){
        document.querySelector('#info-alternative-id').innerHTML = "HTML Version. <a href='.' target='_self'>Refresh page</a> to see original.";
    }

    document.querySelector("#alt_1").innerText = "Sugarcane";
    document.querySelector("#alt_2").innerText = "Blackhaw";
    document.querySelector("#alt_3").innerText = "Cotton";

    enableAltEvents();
    disableCanvasEvents();
}

function enableAltEvents(){
    window.addEventListener('pointerdown', onPointerDownAlt);
}

function onPointerDownAlt(event){
    const scrId = event.srcElement.id;
    if(scrId == "frame-button-id"){
        toggleInfo(true);
        return;
    }
    else if(scrId != "alt_1" && scrId != "alt_2" && scrId != "alt_3"){
        toggleInfo(false);
        openTopic(false);
        return;
    }
    if(event.srcElement.id == "alt_1"){
        trackEvent("topicAlt", "sugar");
        changeTopic("template-sugar");
    }else if(event.srcElement.id == "alt_2"){
        trackEvent("topicAlt", "blackhaw");
        changeTopic("template-bh");
    }else if(event.srcElement.id == "alt_3"){
        trackEvent("topicAlt", "cotton");
        changeTopic("template-cotton");
    }
    toggleInfo(false);
    openTopic(true);
}


async function trackEvent(event, type){
    const url = TRACKINGURL;
    try{
        await fetch(`${url}?event=${event}&type=${type}`);
    }catch(e){
        console.warn("Issue with event");
    }
}

